import React, { useEffect, useState } from "react";
import { useIntersection } from "react-use";

export const IntersectionContext = React.createContext({ inView: true });

export const IntersectionObserver = ({
  isReset = false,
  children,
  reset = true,
  thresholdParam
}) => {
  const [inView, setInView] = useState(false);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold: thresholdParam || 0.6 ,
  });

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > (thresholdParam || 0.6);
    if (inViewNow) {
      return setInView(inViewNow);
    } else if (reset && isReset!==true) {
      return setInView(false);
    }
  }, [intersection, reset]);

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={intersectionRef}>{children}</div>
    </IntersectionContext.Provider>
  );
};